import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  conteiner: {
    position: 'relative'
  },
  img: {
    position: 'relative',
    border: '8px solid #F3BF00'
  },
  text: {
    height: 35,
    width: 35,
    bottom: '12%',
    left: '58%',
    position: 'absolute',
    backgroundColor: theme.palette.secondary.main,
    borderRadius: '50%',
    color: 'white',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    fontWeight: 700
  }
}))
