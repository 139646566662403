import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  container: {
    marginLeft: theme.spacing(9.75)
  },
  responsive:{
    marginLeft: theme.spacing(6)
  },
  list: {
    width: theme.spacing(35) // 45 - 360px
  },
  table: {
    width: theme.spacing(86) // 45 - 360px
  }
}))
