import { combineReducers } from 'redux'

import categories from './categories'
import contact from './contact'
import map from './map'
import seeker from './seeker'
import tour from './tour'
import entities from './entities'
import menuPlace from './menuPlace'
import information from './information'
import layers from './layers'
import location from './location'
import measure from './measure'

export default combineReducers({
  categories,
  contact,
  map,
  seeker,
  tour,
  entities,
  menuPlace,
  information,
  layers,
  location,
  measure
})
