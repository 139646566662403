import React, { useState, useEffect, useRef } from "react";
import PropTypes from "prop-types";
import { Box, Button, Card } from "@material-ui/core";
import img2D from "../../img/2D.png";

const BaseLayersControl = ({ layers, width, height, gap, onLayerSelected }) => {
  // const [layerId, setLayer] =
  const [isOpen, setIsOpen] = useState(false);
  const refMenu = useRef(null);
  const controlGroup = document.querySelector(
    ".mapboxgl-ctrl-top-right .mapboxgl-ctrl-group"
  );
  useEffect(() => {
    if (controlGroup) {
      controlGroup.insertBefore(refMenu.current, controlGroup.firstChild);
    }
  }, [refMenu, controlGroup]);

  const handleOpen = () => {
    setIsOpen(!isOpen);
  };

  const distance = width + gap;
  return (
    <>
      <Box ref={refMenu} />
      <Button
        style={{
          backgroundColor: "#0781AE!important",
          color: "#FFFFFF!important",
        }}
      >
        <Box onClick={handleOpen}>
          <img
            alt="capa base"
            style={{
              position: "relative",
              border: "8px solid #F3BF00",
            }}
            src={img2D}
          />
        </Box>
        {isOpen && (
          <Box
            style={{
              position: "absolute",
              top: 23,
              left: layers.length * -1 * distance,
              display: "flex",
              direction: "row",
            }}
          >
            {layers?.map(({ id, imageUrl }, idx) => (
              <Card
                key={id}
                style={{
                  position: "absolute",
                  top: 0,
                  width,
                  height,
                  left: idx * distance,
                }}
                onClick={() => onLayerSelected(id)}
              >
                <Box
                  style={{
                    position: "absolute",
                    top: 0,
                    width,
                    height,
                    border: "solid 2px lightblue",
                    background: imageUrl,
                    backgroundSize: "cover",
                  }}
                />
              </Card>
            ))}
          </Box>
        )}
      </Button>
    </>
  );
};
BaseLayersControl.propTypes = {
  layers: PropTypes.arrayOf(PropTypes.object).isRequired,
  width: PropTypes.number.isRequired,
  height: PropTypes.number.isRequired,
  gap: PropTypes.number.isRequired,
  onLayerSelected: PropTypes.func.isRequired,
};
export default BaseLayersControl;
