import { useState, useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'

import MapaInteractivoGL from 'utils/MapaInteractivoGL'
import { getLayer } from './configQueries'

const useLayers = () => {
  const { map } = MapaInteractivoGL() || {}
  const [layers, setLayers] = useState([])

  const listlayers = useSelector((state) => state.layers.ids)
  const filters = useSelector((state) => state.layers.filters)
  const layersState = useSelector((state) => state.layers)

  useEffect(() => {
    const auxLayer = listlayers
      .map(getLayer)
      .map(({
        id,
        ...others
      }) => ({
        id,
        ...others,
        opacity: layersState[id].opacity
      }))
    Object.entries(filters)
      .filter(([key, value]) => !!value)
      .map(([key, value]) => {
        const layer = auxLayer?.find(({ id }) => id === key)
        if (layer) {
          layer.cql = value
        }
      })
      
    setLayers(auxLayer)
  }, [filters, listlayers, layersState])

  useEffect(() => {
    const layersReversed = layers.slice(1)
    layersReversed.forEach(({ style: { id: afterId } }, idx) => {
      const beforeId = layers[idx].style.id
      if (map.getLayer(beforeId) && map.getLayer(afterId)) {
        map.moveLayer(afterId, beforeId)
      }
    })
  }, [layers, map])

  useEffect(() => {}, [filters])

  return { layers }
}

export default useLayers
