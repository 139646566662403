import React, {
  useEffect, useRef, useState, cloneElement
} from 'react'
import PropTypes from 'prop-types'
import { actions as locationActions } from 'state/ducks/location'
import { actions as mapActions } from 'state/ducks/map'

import MapaInteractivoGL from 'utils/MapaInteractivoGL'
import { useDispatch } from 'react-redux'

const Popup = ({ coords, children, offset }) => {
  const dispatch = useDispatch()
  const refPopup = useRef(null)
  const refPopupContent = useRef(null)
  const [popup, setPopup] = useState(null)
  useEffect(() => {
    if (popup === null) {
      const engine = MapaInteractivoGL().getMapEngine()
      setPopup(new engine.Popup({ offset, closeButton: true, anchor: 'bottom' }))
    }
  }, [popup, refPopup, offset])

  useEffect(() => {
    if (popup && refPopupContent.current && coords !== null) {
      popup.setLngLat([coords.lng, coords.lat])
      popup.setDOMContent(refPopupContent.current)
    }
  }, [popup, refPopupContent, dispatch, coords])

  useEffect(() => {
    if (popup && refPopupContent.current && coords !== null) {
      popup.on('close', (e) => {
        dispatch(mapActions
          .removeclickedPoint())
        dispatch(locationActions
          .lugar({ place: {}, coordinate: [] }))
      })
    }
  }, [popup, dispatch, coords])

  useEffect(() => {
    if (popup && coords) {
      popup.setLngLat([coords.lng, coords.lat])
    }
  }, [popup, coords])

  useEffect(() => {
    if (popup && !popup.isOpen()) {
      const mapUtils = MapaInteractivoGL()
      popup.addTo(mapUtils.map)
      popup.setDOMContent(refPopupContent.current)
    }
    return () => {
      if (popup) {
        popup.remove()
      }
    }
  }, [popup])

  // {refPopup.current && cloneElement(children, { ref: refPopupContent })}
  return (
    <div ref={refPopup}>
      {refPopup.current && cloneElement(children, { ref: refPopupContent })}
    </div>
  )
}

Popup.propTypes = {
  children: PropTypes.objectOf(PropTypes.any).isRequired,
  coords: PropTypes.objectOf(PropTypes.number).isRequired,
  offset: PropTypes.number.isRequired
}

export default Popup
