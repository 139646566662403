import { useEffect } from 'react'

const useZoom = ({ map, lat, lng }) => {
  useEffect(() => {
    if (lat && lng && map) {
      map.flyTo({
        center: [lng, lat],
        zoom: 15
      })
    }
  }, [lat, lng, map])
}

export default useZoom
