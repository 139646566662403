const imageToUint8Array = async (image, context) => new Promise((resolve) => {
  context.drawImage(image, 0, 0)
  const imgData = context.getImageData(0, 0, image.width, image.height)
  resolve(imgData)
})

const loadImage = (url) => new Promise((resolve, reject) => {
  const img = new Image()
  img.onload = () => {
    resolve(img)
  }
  img.onerror = reject
  img.src = url
  img.setAttribute('crossOrigin', '')
})

// eslint-disable-next-line import/prefer-default-export
export const overlap = async (imageA, imageB) => {
  const imgA = await loadImage(imageB)
  const imgB = await loadImage(imageA)

  const canvas = document.createElement('canvas')
  const ctx = canvas.getContext('2d')

  await imageToUint8Array(imgA, ctx)
  const result = await imageToUint8Array(imgB, ctx)

  ctx.clearRect(0, 0, canvas.width, canvas.height)

  return result
}
