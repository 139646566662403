import React from "react";
import Logo from "../../img/lujan.png";
import { Avatar, Link } from "@material-ui/core";

export default () => (
  <Link href="https://lujandecuyo.gob.ar/innovacion-smartcity/">
    <Avatar
      src={Logo}
      style={{
        margin: "0 auto",
        width: "35px",
        height: "35px",
        overflow: "initial",
      }}
    />
  </Link>
);
