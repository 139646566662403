import { createSlice } from '@reduxjs/toolkit'

const menuPlace = createSlice({
  name: 'menuPlace',
  initialState: {
    placeFromSelected: null,
    placeToSelected: null,
    showMenu: false,
    direction: null
  },
  reducers: {
    placeToSelected: (draftState, action) => {
      draftState.placeToSelected = action.payload
    },
    placeFromSelected: (draftState, action) => {
      draftState.placeFromSelected = action.payload
    },
    showMenu: (draftState, action) => {
      draftState.showMenu = action.payload
    },
    toggleCoordsFromTo: (draftState) => {
      const auxFrom = draftState.placeFromSelected
      draftState.placeFromSelected = draftState.placeToSelected
      draftState.placeToSelected = auxFrom
    }
  }
})

export default menuPlace.reducer

const actions = { ...menuPlace.actions }
export { actions }
