import { makeStyles } from '@material-ui/core/styles'

export default makeStyles(() => ({
  tutorialModal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  container: {
    display: 'flex',
    flexDirection: 'column',
    width: '100%',
    maxWidth: 600,
    height: 500,
    backgroundColor: 'rgba(255, 255, 255, 0.8)',
    backdropFilter: 'blur(3px)',
    justifyContent: 'space-between',
    background: 'linear-gradient(180deg, rgba(245, 188, 44, 0.245) 0%, rgba(117, 32, 126, 0) 50%)'
  },
  sectionModal: {
    width: '100%',
    display: 'flex',
    alignItems: 'flex-start',
    justifyContent: 'center',
    gap: 20
  },
  sectionBienvenida: {
    width: '100%',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center'
  },
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'flex-start',
    alignItems: 'center',
    backgroundColor: '#F3BF00',
    padding: 8
  },
  title: {
    color: '#575756',
    height: 60,
    width: 160,
    fontWeight: 800,
    fontSize: '2.2vmin',
    display: 'flex',
    	alignItems: 'center'
  },
  iconoTutorial: {
    width: 40,
    height: 40,
    paddingLeft: 12,
    paddingRight: 48,
    display: 'inline-block'
  },
  iconClose: {
    color: '#1F3464'
  },
  imgpage: {
    width: '100%',
    height: 'auto'
  },
  containerText: {
    flexBasis: 280,
    display: 'flex',
    flexDirection: 'column'
  },
  containerTutorial: {
    display: 'flex',
    width: '100%',
    height: '70%',
    marginTop: 40,
    justifyContent: 'space-between'
  },
  containerImg: {
    flexBasis: 240
  },
  colorText: {
    color: '#575756'
  },
  titleStep: {
    fontWeight: 700,
    color: '#575756',
    fontSize: '2vmin'
  },
  mainText: {
    fontSize: '1.55vmin',
    color: '#575756'
  },
  mainText1: {
    fontSize: '1.55vmin',
    color: '#575756',
    paddingLeft: '5%',
    paddingTop: 10,
    width: '95%'
  },
  mainTextTools: {
    fontSize: '1.55vmin',
    color: '#575756',
    paddingTop: 20,
    width: '95%'
  },
  titleBienvenida: {
    fontWeight: 400,
    color: '#575756',
    fontSize: '6vmin'
  },
  textBienvenida: {
    fontSize: '3vmin',
    color: '#575756',
    textAlign: 'right'
  },
  titleStep1: {
    fontWeight: 700,
    paddingLeft: '5%',
    color: '#575756',
    fontSize: '2vmin'
  }
}))
