import { useState, useEffect } from 'react'
import { getFilterOptions } from 'utils/apiConfig'

const useFilterOptions = (filters) => {
  const [options, setOptions] = useState([])

  useEffect(() => {
    let isSubscribed = true
    const attribute=  filters[0];
    const layerName= filters[1];
    const max= filters[2];
    if (attribute) {
      const { url, ...dataRequest } = getFilterOptions({
        layerName,
        attribute,
        max
      })

      fetch(url, dataRequest)
        .then((response) => response.json())
        .then((data) => {
          const aux = data.values.map((e) => ({ id: e, label: e }))
          if (isSubscribed) {
            setOptions(aux)
          }
        })
    }

    return () => {
      isSubscribed = false
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return { options }
}

export default useFilterOptions
