import React, { useEffect, useState } from "react";
import ReactHtmlParser from "react-html-parser";
import {
  Box,
  Typography,
  Checkbox,
  FormGroup,
  FormControlLabel,
  Button,
  ButtonGroup,
  Slider,
  IconButton,
  Link,
  SvgIcon,
  Divider,
} from "@material-ui/core";
import Scrollbar from "react-smooth-scrollbar";
import { actions as mapActions, actions as categories } from "state/ducks/map";
import { useDispatch, useSelector } from "react-redux";
import CustomOpcion from "theme/wrappers/CustomOption";
import {
  List,
  Opacity,
  FilterFrames,
  FilterCenterFocus,
} from "@material-ui/icons";
import { getImgTooltip } from "utils/apiConfig";
import CircleUnchecked from "@material-ui/icons/RadioButtonUnchecked";
import ClearIcon from "@material-ui/icons/Clear";
import { actions as layersActions } from "state/ducks/layers";
import { getLayer, getSubcategoriesLayers } from "utils/configQueries";
import { withStyles, makeStyles } from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";
import Modal from "@material-ui/core/Modal";
import icons from "utils/svgIcons";
import useStyles from "./styles";

const LayerBox = ({
  options = [],
  onChangeOption = () => {},
  optionsSelected = null,
  title,
  name,
  footText,
  opacityText,
  valores = [],
  onChangeRange = () => {},
  rangeSelected = [],
  layerId,
  filters,
  link,
  urlDownload,
  dragProvided,
  bbox,
}) => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const valueOpacity = useSelector((state) => state.layers);
  const [opacityControl, setOpacityControl] = useState(false);
  const [rangeControl, setRangeControl] = useState(false);
  const [references, setReferences] = useState(false);
  const [opcionLink, setOpcionLink] = useState(false);
  const [filter, setFilter] = useState(false);
  const layer = getLayer(layerId);
  getSubcategoriesLayers().forEach((element) => {
    element.layers.forEach((capa) => {
      if (capa === layer.id) {
        layer.color = element.color;
      }
    });
  });
  const useStyle = makeStyles(() => ({
    iconActive: {
      color: layer.color,
    },
    icon: {
      "&:hover": { color: layer.color },
    },
  }));

  const LightTooltip = withStyles((theme) => ({
    tooltip: {
      marginRight: 5,
      marginTop: 10,
      backgroundColor: "#a09898",
      color: "white",
      boxShadow: theme.shadows[1],
      fontSize: 12,
    },
  }))(Tooltip);

  const classIcon = useStyle();

  const handleChange = (event, newValue) => {
    onChangeRange(value, !rangeSelected.includes(value));
    setValue(newValue);
    dispatch(mapActions.removeclickedPoint());
  };
  const designDecimal = () => {
    const decimales = valores
      .map((e) => {
        if (e.minCondition.toString().split(".")[1]?.length) {
          return parseInt(e.minCondition.toString().split(".")[1]?.length);
        }
      })
      .filter((e) => e !== undefined)
      .sort();
    return decimales[decimales.length - 1];
  };
  const maxDecimal = designDecimal();
  const marks = [
    {
      value: valores[0]?.minCondition,
      label: valores[0]?.minCondition,
    },
    {
      value: valores[valores.length - 1]?.maxCondition,
      label: valores[valores.length - 1]?.maxCondition,
    },
  ];
  const [value, setValue] = useState([]);

  const fitBounds = async () => {
    await fetch(`${bbox}?format=json`)
      .then((response) => response.json())
      .then((data) => {
        let coordinates = [
          data.dataset.ll_bbox_polygon.coordinates[0][1],
          data.dataset.ll_bbox_polygon.coordinates[0][3],
        ];
        dispatch(mapActions.setBbox(coordinates));
      });
  };

  return (
    <Box className={classes.box}>
      <Box {...dragProvided.dragHandleProps}>
        <Box className={classes.headerBox}>
          <LightTooltip title="Cerrar" placement="top" arrow>
            <IconButton
              style={{
                padding: 0,
                position: "absolute",
                right: -17,
                top: -17,
              }}
              data-tut="buttonDelete"
              onClick={() => dispatch(layersActions.removeLayer({ layerId }))}
            >
              <ClearIcon className={classes.iconClose} />
            </IconButton>
          </LightTooltip>
          <Typography className={classes.titleBox}>{name}</Typography>
          <Box className={classes.iconButton} />
        </Box>
        <Box className={classes.containerButtons}>
          {filters?.find(({ type }) => type === "options")?.type && (
            <LightTooltip
              title="Opciones de selección"
              placement="bottom"
              arrow
            >
              <Button
                className={[classes.opcionButton, classIcon.iconActive]}
                style={{ borderLeft: "none" }}
                onClick={() => setFilter(!filter)}
              >
                <FilterFrames />
              </Button>
            </LightTooltip>
          )}

          <LightTooltip title="Referencias" placement="bottom" arrow>
            <Button
              className={classes.opcionButton}
              onClick={() => setReferences(!references)}
            >
              <List />
            </Button>
          </LightTooltip>

          <LightTooltip title="Zoom a la capa" placement="bottom" arrow>
            <Button
              className={classes.opcionButton}
              onClick={() => fitBounds()}
            >
              <FilterCenterFocus />
            </Button>
          </LightTooltip>

          <Link href={urlDownload}>
            <LightTooltip title="Descargar" placement="bottom" arrow>
              <Button className={classes.opcionButton}>
                <SvgIcon data-tut="buttonMove" style={{ marginTop: 5 }}>
                  {icons.find((i) => i.id === "download").path}
                </SvgIcon>
              </Button>
            </LightTooltip>
          </Link>

          <LightTooltip title="Opacidad" placement="bottom" arrow>
            <Button
              className={[classes.opcionButton, classIcon.iconActive]}
              onClick={() => setOpacityControl(!opacityControl)}
            >
              <Opacity />
            </Button>
          </LightTooltip>

          {filters?.find(({ type }) => type === "quantile")?.type && (
            <LightTooltip title="Rangos" placement="bottom" arrow>
              <Button
                className={[classes.opcionButton, classIcon.iconActive]}
                onClick={() => {
                  setRangeControl(!rangeControl);
                  setValue([
                    valores[0]?.minCondition !== undefined
                      ? valores[0]?.minCondition
                      : 1,
                    valores[valores.length - 1]?.maxCondition,
                  ]);
                  onChangeRange(
                    [
                      valores[0]?.minCondition !== undefined
                        ? valores[0]?.minCondition
                        : 1,
                      Number(valores[valores.length - 1]?.maxCondition),
                    ],
                    !rangeSelected.includes([
                      Number(valores[0]?.minCondition),
                      Number(valores[valores.length - 1]?.maxCondition),
                    ])
                  );
                }}
              >
                <SvgIcon data-tut="buttonMove" style={{ marginTop: 5 }}>
                  {icons.find((i) => i.id === "range").path}
                </SvgIcon>
              </Button>
            </LightTooltip>
          )}
          <SvgIcon data-tut="buttonMove" style={{ marginTop: 5 }}>
            {icons.find((i) => i.id === "dragable").path}
          </SvgIcon>
        </Box>
        {filter && (
          <>
            {filters?.find(({ type }) => type === "options")?.type ? (
              <Box className={classes.subtitleOption}>
                {filters[0].name
                  ? `Filtrar por ${filters[0].name}`
                  : "Seleccione la opcion:"}
              </Box>
            ) : null}
            <FormGroup className={classes.formGroupBox}>
              <Scrollbar>
                {options.map(({ id, label }) => (
                  <FormControlLabel
                    className={classes.form}
                    key={id}
                    control={
                      <Checkbox
                        {...label}
                        defaultChecked
                        sx={{ "& .MuiSvgIcon-root": { fontSize: 28 } }}
                      />
                    }
                    label={label}
                  />
                ))}
              </Scrollbar>
            </FormGroup>
            <Divider />
          </>
        )}
      </Box>
      {references && (
        <>
          <img src={getImgTooltip(title)} style={{ paddingTop: 10 }} />
          <Divider style={{ marginTop: 10 }} />
        </>
      )}
      {opcionLink && (
        <Box style={{ paddingTop: 15, paddingBottom: 10 }}>
          <Typography variant="h7">Link a datos de las capas:</Typography>

          {link.map((element) => (
            <Box className={classes.subtitleQuantiles}>
              <Link
                style={{ color: layer.color, padding: 7 }}
                href={element.split(",")[1]}
              >
                {element.split(",")[0]}
              </Link>
            </Box>
          ))}
        </Box>
      )}
      {rangeControl && filters?.find(({ type }) => type === "quantile")?.type && (
        <>
          <Box className={classes.subtitleQuantiles} style={{ paddingTop: 25 }}>
            {filters[1].name
              ? `Filtrar por ${filters[1].name}`
              : "Seleccione el rango de valores:"}
          </Box>
          <Slider
            style={{
              color: layer.color,
              width: 200,
              marginLeft: 14,
              marginTop: 10,
            }}
            value={value}
            min={valores[0]?.minCondition}
            max={valores[valores.length - 1]?.maxCondition}
            step={
              maxDecimal === 2
                ? 0.01
                : maxDecimal === 3
                ? 0.0001
                : maxDecimal >= 4
                ? 0.0001
                : 1
            }
            onChange={handleChange}
            valueLabelDisplay="auto"
            aria-labelledby="range-slider"
            marks={marks}
          />
          <Typography variant="h5" className={classes.footBox}>
            {footText}
          </Typography>

          <Divider style={{ marginTop: 10 }} />
        </>
      )}
      {opacityControl && (
        <>
          <Box {...dragProvided.dragHandleProps}>
            <Typography variant="h5" className={classes.footBox}>
              {opacityText}
            </Typography>
          </Box>
          <Slider
            style={{ color: "#F3BF00" }}
            defaultValue={valueOpacity[layerId]?.opacity}
            min={0}
            max={100}
            onChange={(_, newValue) => {
              dispatch(mapActions.removeclickedPoint());
              dispatch(
                layersActions.layerSliderChanged({ layerId, opacity: newValue })
              );
            }}
          />
        </>
      )}
    </Box>
  );
};

export default LayerBox;
