import { createTheme } from "@material-ui/core/styles";
import { red } from "@material-ui/core/colors";
import { white } from "material-ui/styles/colors";

const spacing = 8;
const theme = createTheme({
  overrides: {
    spacing,
    MuiPagination: {
      ul: {
        justifyContent: "center",
        color: "black",
        fontSize: 15,
        paddingTop: 5,
      },
    },
    MuiPaginationItem: {
      root: {
        height: 25,
        width: 25,
      },
      icon: {
        color: "black",
      },
    },
    MuiBadge: {
      colorSecondary: {
        color: "#F3BF00",
        border: "1px solid #F3BF00",
        backgroundColor: "#575756",
        fontWeight: "bold",
        fontSize: 12,
      },
    },
    MuiCssBaseline: {
      "@global": {
        html: {
          fontSize: 14,
          backgroundColor: white[500],
        },
      },
    },
    MuiSvgIcon: {
      root: {
        colorPrimary: "#fed304",
      },
    },
    MuiSlider: {
      root: {
        width: "95%",
      },
    },
    MuiAccordion: {
      root: {
        marginBottom: spacing,
        boxShadow: "none",
      },
    },
    MuiAccordionSummary: {
      root: {
        minHeight: 0,
        "&$expanded": {
          minHeight: 0,
        },
      },
      content: {
        margin: "0px!important",
      },
    },
    MuiAccordionDetails: {
      root: {
        padding: 0,
      },
    },
    MuiFormLabel: {
      root: {
        color: "#595958",
      },
    },
    MuiListItemText: {
      secondary: {
        color: "#0B72BB",
      },
    },
    MuiDrawer: {
      paperAnchorLeft: {
        width: 440,
      },
      paperAnchorDockedLeft: {
        borderRight: "0px",
      },
    },
    MuiSnackbarContent: {
      root: {
        backgroundColor: "#F3BF00 !important",
        color: "#575756",
      },
    },
  },
  typography: {
    fontFamily: ["Nunito", "Open Sans"].join(","),
    caption: {
      letterSpacing: 0,
      fontSize: "11.5px",
      lineHeight: "17px",
    },
    h5: {
      fontSize: "1.5rem",
    },
    body1: {},
  },
  palette: {
    text: {
      primary: "#595958",
      secondary: "#D9D9D9",
      Info: "#00f",
      link: "#909090",
    },
    action: {
      active: "#22142b",
      hoverOpacity: 0.1,
    },
    primary: {
      main: "#FFFFFF",
    },
    secondary: {
      main: "#F3BF00",
    },
    error: {
      main: red.A400,
    },
    background: {
      default: "#fff",
    },
  },
});
export default theme;
