import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  card: {
    padding: theme.spacing(1),
    minHeight: theme.spacing(11),
    marginBottom: theme.spacing(2.5),
    backgroundColor: '#F1F1F3',
    borderRadius: '5px',
    borderLeftStyle: 'solid',
    borderWidth: 'thick',
    lineHeight: theme.spacing(20)
  },
  conteiner: {
    display: 'flex',
    flexDirection: 'column',
    width: '10%'
  },
  conteinerDatos: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40
  },
  datosAndDescription: {
    width: '80%',
    paddingLeft: 20,
    paddingRight: 20
  },
  description: {
    width: '100%'
  },
  descriptionUsos: {
    paddingLeft: '20%'
  },
  sectionTitle: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 25,
    paddingLeft: 25,
    minWidth: 280,
    height: 94
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 900,
    paddingLeft: 8
  },
  iconParcel: {
    display: 'flex',
    justifyContent: 'center',
    height: '100%',
    alignItems: 'center'
  },
  subConteiner: {
    height: '88%'
  },
  edfNotAvailable: {
    padding: 40, fontWeight: 'bolder'
  }
}))
