import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  conteiner: {
    display: 'flex',
    flexDirection: 'column',
    width: '10%'
  },
  conteinerDatos: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10
  },
  sectionTitle: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 25,
    paddingLeft: 25,
    width: '100%',
    height: 94
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 900,
    paddingLeft: 8,
    marginTop: -3
  },
  info: {
    borderRadius: 5,
    backgroundColor: '#D9D9D9',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    marginTop: 10
  },
  conteinerDownload: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonDownload: {
    display: 'flex',
    padding: 0,
    minWidth: 30
  },
  icon: {
    fontSize: 40
  },
  capa: {
    color: 'black'
  },
  formControl: {
    margin: 0
  },
  options: {
    width: '100%'
  }
}))
