import React, { useEffect, useRef } from "react";
import MapaInteractivoGL from "utils/MapaInteractivoGL";

const Layer = ({
  sourceId,
  sourceConfig,
  cql = "",
  layerConfig,
  opacity,
  listIdLayersState,
  layers,
  id,
}) => {
  const { map } = MapaInteractivoGL();
  const cqlRef = useRef();
  const defaultId = "gl-draw-line.cold";

  const buscarLayers = () => {
    const targetIndex = listIdLayersState.indexOf(id);

    let beforeLayer = defaultId;

    if (targetIndex > 0) {
      let idLayer = listIdLayersState[targetIndex - 1];
      const index = layers.findIndex((layer) => layer.id === idLayer);
      if (index >= 0) {
        beforeLayer = layers[index]?.style?.id || defaultId;
      } else {
        beforeLayer = defaultId;
      }
    }

    return beforeLayer;
  };

  useEffect(() => {
    map.setPaintProperty(
      layerConfig.id,
      `${layerConfig.type}-opacity`,
      opacity / 100
    );
  }, [opacity]);

  useEffect(() => {
    if (cqlRef.current !== cql && sourceId && map.getSource(sourceId)) {
      map.removeLayer(layerConfig.id);
      map.removeSource(sourceId);
    }

    if (sourceId && !map.getSource(sourceId)) {
      const sourceConfigAux = {
        ...sourceConfig,
        tiles: [...sourceConfig.tiles],
      };
      const cqlFilter = [];
      if (cql?.options?.length) {
        cqlFilter.push(cql.options);
      }
      if (cql?.range?.length) {
        cqlFilter.push(cql.range);
      }
      if (cqlFilter.length) {
        sourceConfigAux.tiles[0] += `&CQL_FILTER=(${cqlFilter.join("AND")})`;
      }
      map.addSource(sourceId, sourceConfigAux);
    }
    if (layerConfig?.id) {
      let beforeId = buscarLayers(layerConfig.id);

      if (layerConfig?.id && map.getLayer(beforeId)) {
        map.addLayer(layerConfig, beforeId);
      } else if (layerConfig?.id && !map.getLayer(layerConfig.id)) {
        map.addLayer(layerConfig, beforeId);
      }
      map.setLayoutProperty(
        layerConfig.id,
        "visibility",
        layerConfig.layout.visibility
      );
    }

    return () => {
      if (map && map.getStyle()) {
        if (layerConfig?.id && map.getLayer(layerConfig.id)) {
          map.removeLayer(layerConfig.id);
          map.removeSource(sourceId);
        } else if (map.getSource(sourceId)) {
          map.removeLayer(layerConfig.id);
          map.removeSource(sourceId);
        }
      }
    };
  }, [map, sourceId, sourceConfig, cql, layerConfig]);

  return <input id={layerConfig?.id ?? "without_id"} type="hidden" />;
};

export default Layer;
