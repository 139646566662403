import React from 'react'

import { Button, Typography } from '@material-ui/core'

import { actions } from 'state/ducks/categories'
import { useSelector, useDispatch } from 'react-redux'

import PropTypes from 'prop-types'

const InfoCard = ({
  title
}) => {
  const sectionId = useSelector((state) => state.categories.sectionInfo)
  const dispatch = useDispatch()

  return (
    <Button
      style={{ width: '100%', backgroundColor: sectionId !== title ? '#F3BF00' : 'white', borderRadius: 0, paddingTop: 10, paddingBottom: 10 }}
      onClick={() => {
        dispatch(actions.sectionSelected({ title }))
      }}
    >
      <Typography style={{ textTransform: 'capitalize' }}>
        {title}
      </Typography>
    </Button>
  )
}

InfoCard.propTypes = {
  title: PropTypes.string.isRequired
}

export default InfoCard
