import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    display: 'flex',
    alignItems: 'center',
    width: '300px',
    height: 40,
    [theme.breakpoints.down('sm')]: {
      width: '218px!important',
      marginRight: 7,
      position: 'absolute',
      top: 20,
      right: -17
    },
    marginRight: 140,
    borderRadius: 0
  },
  scrollbar: {
    maxHeight: 500,
    maxWidth: 300,
    [theme.breakpoints.down('sm')]: {
      position: 'absolute',
      top: 120
    }
  },
  input: {
    paddingLeft: 10,
    width: '100%'
  },
  iconButton: {
    padding: 10
  },
  list: {
    fontSize: '0.6rem',
    width: '300px',
    display: 'flex',
    zIndex: 10000,
    [theme.breakpoints.down('sm')]: {
      width: 190,
      right: 50

    }
  },
  paper: {
    [theme.breakpoints.down('sm')]: {
      width: 190
    }
  },
  icon: {
    borderRadius: 0,
    backgroundColor: '#F3BF00',
    height:40,
    color: 'black',
    '&:hover': { backgroundColor: '#726d6d', cursor: 'pointer', opacity: 0.9 }
  },
  buttonFilter: {
    paddingTop: 0,
    textTransform: 'none',
    '&:hover': { backgroundColor: 'transparent !important' }
  }
}))
