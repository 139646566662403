import { createSlice } from '@reduxjs/toolkit'

const information = createSlice({
  name: 'information',
  initialState: {
    data: {
      parcel: {},
      utils: {},
      transp: {}
    }
  },
  reducers: {
    placeSelected: (draftState, {
      payload: {
        parcelData, utilsData, infoTransp
      }
    }) => {
      draftState.data.parcel = parcelData
      draftState.data.utils = utilsData
      draftState.data.transp = infoTransp
    }
  }
})

export default information.reducer

const actions = { ...information.actions }
export { actions }
