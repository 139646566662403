import React, { useEffect, useState } from 'react'
import { Typography, Box } from '@material-ui/core'
import icons from 'utils/svgIcons'
import ContainerBar from 'components/Sections/ContainerBar'
import {
  getInformation
} from 'utils/configQueries'
import { useDispatch, useSelector } from 'react-redux'
import { actions as layersActions } from 'state/ducks/layers'
import useStyles from './styles'
import { data } from './data'
import InfoCard from './InfoCard'

const Information = () => {
  const sectionId = useSelector((state) => state.categories.sectionInfo)
  const info = useSelector((state) => state.layers.layerInfo)
  const dispatch = useDispatch()
  const classes = useStyles()

  const [panelDatos, setPanelDatos] = useState([])

  useEffect(() => {
    if (info) {
      setPanelDatos(data([info]))
      dispatch(layersActions.layerInfoData(data([info])))
    }
  }, [info])

  const checkDescription = (objeto) => (
    (objeto.alert && objeto.alert.some((item) => item.description))
      || (objeto.aft && objeto.aft.some((item) => item.description))
      || objeto.edf[0]
  )

  return (
    <ContainerBar
      className={classes.container}
      type="list"
    >
      <Box className={classes.sectionTitle}>
        <Box style={{ padding: '0' }}>
          {icons.find((i) => i.id === 'InformacionDrawer')?.path}
        </Box>
        <Typography className={classes.title}>Información</Typography>
      </Box>
      <Box style={{ display: 'flex' }}>
        {
      getInformation().map(({
        id, title, description, color
      }) => (
        <InfoCard
          key={id}
          id={id}
          title={title}
          description={description}
          color={color}
        />
      ))
    }
      </Box>
      {sectionId === 'Datos' && (
        <Box className={classes.subConteiner}>
          {
             panelDatos?.datos ? (
               <Box>
                 {
          panelDatos?.datos?.map(({
            title, description
          }, index) => (
            <Box className={classes.conteinerDatos} style={{ backgroundColor: index % 2 === 0 ? '#FAE599' : '#ffffff' }}>
              <Typography className={classes.datosAndDescription}>
                {title}
              </Typography>
              <Typography className={classes.description}>
                {description}
              </Typography>
            </Box>
          ))
          }
               </Box>
             ) : (
               <Box className={classes.iconParcel}>
                 {icons.find((i) => i.id === 'iconParcel')?.path}
               </Box>
)
        }
        </Box>
      )}
      {sectionId === 'Usos permitidos' && (
        <Box className={classes.subConteiner}>
          {panelDatos?.usosPermitidos ? (
            <Box>
              {
        panelDatos?.usosPermitidos?.map(({
          title, color
        }) => (
          title ? (
            <Box className={classes.conteinerDatos} style={{ backgroundColor: color }}>
              <Typography className={classes.datosAndDescription}>
                <a
                  href={title}
                  target="_blanck"
                >
                  Ver reglamentos de usos permitidos.
                </a>
              </Typography>
            </Box>
          ) : (
            <Typography className={classes.edfNotAvailable}>
              No disponible debido a la condición de bien patrimonial.
              Las restricciones y regulaciones especiales aplican en este lugar.
            </Typography>
          )
        ))
              }
            </Box>
          ) : (
            <Box className={classes.iconParcel}>
              {icons.find((i) => i.id === 'iconParcel')?.path}
            </Box>
)}
        </Box>
      )}
      {sectionId === 'Edificabilidad' && (
      <Box className={classes.subConteiner}>
        {
            panelDatos?.edificabilidad?.edf.map(({
              title, description
            }, index) => (
              <Box className={classes.conteinerDatos} style={{ backgroundColor: index % 2 === 0 ? '#FAE599' : '#ffffff' }}>
                <Typography className={classes.datosAndDescription}>
                  {title}
                </Typography>
                <Typography className={classes.description}>
                  {description}
                </Typography>
              </Box>
            ))
                 }
        {(panelDatos?.edificabilidad?.aft
        && panelDatos?.edificabilidad?.alert
         && panelDatos?.edificabilidad?.edf) ? (
           <Box>
             {(checkDescription(panelDatos?.edificabilidad)) ? (
               <>
                 {
        panelDatos?.edificabilidad?.alert.map(({
          title, description
        }) => (description && (
          <Box style={{ display: 'flex', justifyContent: 'center', padding: 15 }}>
            <Box style={{ backgroundColor: '#F1F1F1', width: '90%', padding: 10 }}>
              <Typography style={{ color: '#E8327C', fontWeight: 700 }}>
                {title}
              </Typography>
              <Typography>
                {description}
              </Typography>
            </Box>
          </Box>
        )
        ))
}
                 {
        panelDatos?.edificabilidad?.aft.map(({
          title, description
        }) => (description && (
          <Box style={{ display: 'flex', justifyContent: 'center', padding: 15 }}>
            <Box style={{ backgroundColor: '#F1F1F1', width: '90%', padding: 10 }}>
              <Typography style={{ color: '#E8327C', fontWeight: 700 }}>
                {title}
              </Typography>
              <Typography>
                {description}
              </Typography>
            </Box>
          </Box>
        )
        ))
}
               </>
             ) : (
               <Typography className={classes.edfNotAvailable}>
                 No disponible debido a la condición de bien patrimonial.
                 Las restricciones y regulaciones especiales aplican en este lugar.
               </Typography>
             )}
           </Box>
          ) : (
            <Box className={classes.iconParcel}>
              {icons.find((i) => i.id === 'iconParcel')?.path}
            </Box>
          )}
      </Box>
      )}
    </ContainerBar>
  )
}

export default Information
