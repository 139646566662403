import hash from 'object-hash'

const placesSliceConfig = {
  initialState: {
    places: {
      byId: {},
      recents: [],
      recentsMax: 5,
      favorites: [],
      favoritesMax: 10
    }
  },
  reducers: {
    /**
     * Agrega el lugar sino existe y se coloca como el último reciente.
     */
    placeSelected: (draftState, { payload: { nombre, coords } }) => {
      const place = { nombre, coords }
      const {
        places: {
          byId, recents, recentsMax
        }
      } = draftState
      const id = hash(place)
      // Si no existe se agrega a byId
      if (!byId[id]) {
        byId[id] = place
      } else {
        // Si existe se elimina de recientes
        const exist = recents.find((fav) => fav.nombre === nombre)
        const idx = recents.indexOf(exist)
        if (idx >= 0) {
          recents.splice(idx, 1)
        }
      }
      // Se lo coloca como el más reciente
      recents.unshift(place)
      if (recents.length > recentsMax) {
        recents.pop()
      }
    },
    addFavorite: (draftState, {
      payload: {
        id, nombre, direccion, coords, idRoute, textFrom, textTo
      }
    }) => {
      const {
        places: {
          byId, favorites, favoritesMax
        }
      } = draftState
      if (nombre) {
        const place = {
          type: 'place', id, nombre, direccion, coords
        }
        const idPlace = hash(place)
        if (!byId[idPlace]) {
          byId[idPlace] = place
        }
        const exist = favorites.find((fav) => fav.id === id)
        const idx = favorites.indexOf(exist)
        if (idx >= 0) {
          favorites.splice(idx, 1)
        }
        favorites.unshift(place)
        if (favorites.length > favoritesMax) {
          // TODO: En caso de haber límite y alcanzarlo mostrar una Alerta
          favorites.pop()
        }
      } else if (idRoute) {
        const route = {
          type: 'route', id: idRoute, from: textFrom, to: textTo
        }
        const exist = favorites.find((fav) => fav.idFav === idRoute)
        const idx = favorites.indexOf(exist)
        if (idx >= 0) {
          favorites.splice(idx, 1)
        }
        favorites.unshift(route)
        if (favorites.length > favoritesMax) {
          favorites.pop()
        }
      }
    },
    renameFav: (draftState, { payload: { id, newValue } }) => {
      const auxFav = draftState.places.favorites.map((fav) => {
        if (fav.id === id) {
          // TODO: evitar reassign
          // eslint-disable-next-line no-param-reassign
          fav.nombre = newValue
        }
        return fav
      })
      draftState.places.favorites = auxFav
    },
    deleteFav: (draftState, { payload: { id } }) => {
      const auxFav = draftState.places.favorites.filter((fav) => fav.id !== id)
      draftState.places.favorites = auxFav
    }
  }
}

export default placesSliceConfig
