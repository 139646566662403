import { createSlice } from '@reduxjs/toolkit'

const location = createSlice({
  name: 'location',
  initialState: {
    place: {}
  },
  reducers: {
    lugar: (draftState, action) => {
      draftState.place = action.payload
    }
  }
})

export default location.reducer

const actions = { ...location.actions }
export { actions }
