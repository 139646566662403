import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  root: {
    display: 'flex'
  },
  drawerPaper: {
    position: 'relative',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen
    })
  },
  drawer: {
    width: 100,
    flexShrink: 0
  },
  buttonExpanded: {
    width: 17,
    height: 135,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    background: theme.palette.secondary.main,
    borderRadius: '0%',
    color:'white',
    '&:hover': { backgroundColor: '#0B72BB' },
  },
  navMenu: {
    background: theme.palette.secondary.main,
    width: theme.spacing(9.75),
    height: '100vh',
    display: 'flex',
    justifyContent: 'center',
    position: 'fixed',
    top: 0,
    left: '-100%',
    transition: '850ms',
  },
  active:{
    left: 0,
    transition: 350
  }
}))
