import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  containerButtons: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-around'
  },
  box: {
    display: 'block',
    height: 'auto',
    color: 'grey',
    padding: 12,
    marginTop: '5px',
    width: 260,
    backgroundColor: '#ffffff',
    boxShadow: '0px 4px 4px rgba(0, 0, 0, 0.25)',
    borderRadius: 8,
    [theme.breakpoints.down('sm')]: {
      width: 220
    }
  },
  headerBox: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    position: 'relative',
    margin: 12
  },
  iconButton: {
    display: 'inline-flex'
  },
  moveButton: {
    '&:hover': {
      background: 'none'
    }
  },
  subtitleOption: {
    display: 'flex',
    height: 20,
    paddingTop: 8,
    justifyContent: 'space-between'
  },
  subtitleQuantiles: {
    display: 'flex',
    height: 20,
    paddingTop: 15,
    justifyContent: 'space-between'
  },
  titleBox: {
    textAlign: 'left',
    fontSize: 13
  },
  formGroupBox: {
    top: 25,
    maxHeight: 225,
    display: 'flex',
    flexWrap: 'nowrap'
  },
  buttonGroupBox: {
    marginTop: '10px',
    display: 'flex'
  },
  range: {
    height: '20px',
    color: '#f2f2f2',
    '&:hover': { backgroundColor: 'white', opacity: 0.8 }
  },
  footBox: {
    fontSize: '12px',
    textAlign: 'left',
    fontStyle: 'italic',
    marginTop: '8px'
  },
  Container: {
    top: '5px',
    left: '460px',
    maxHeight: '100%',
    height: 'auto',
    position: 'absolute',
    display: 'block',
    zIndex: '1',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      left: '334px'
    }
  },
  Container2: {
    top: '5px',
    left: '86px',
    maxHeight: '100%',
    height: 'auto',
    position: 'absolute',
    display: 'block',
    zIndex: '2',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      left: '64px',
      top: 53,
      maxHeight: 500
    }
  },
  boxMobile: {
    top: '5px',
    left: '86px',
    maxHeight: '100%',
    height: 'auto',
    position: 'absolute',
    display: 'block',
    zIndex: '2',
    overflow: 'auto',
    [theme.breakpoints.down('sm')]: {
      top: 53,
      left: 10,
      maxHeight: 500
    }
  },
  acorddionBox: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    backgroundColor: '#0B72BB',
    borderRadius: 8,
    height: 25
  },
  form: {
    margin: 1,
    width: 200
  },
  opcionButton: {
    minWidth: 25,
    height: 30,
    borderRadius: 0,
    color: '#F3BF00'
  },
  iconClose: {
    width: '18px !important',
    height: '18px !important'
  },
  bodyModal: {
    position: 'absolute',
    width: 500,
    top: '20%',
    left: '40%',
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    paddingLeft: 20,
    padding: 5,
    paddingRight: 0

  }
}))
