const baseUrl = process.env.REACT_APP_DATABASE_URL

const constantData = {
  infoLayers: {}
}
const getFeatureInfo = async ({
  layer, x, y, height, width, bbox
}) => {
  const id = `${layer}_${x}_ ${y}`
  constantData.infoLayers[id] = constantData.infoLayers[id]
    || (await fetch(`${baseUrl}geoserver/ows?service=WMS&version=1.1.1&request=GetFeatureInfo&exceptions=application/json&id=6ee5dd7b-aca9-455f-b0a7-b9a6c71a4a40&layers=${layer}&query_layers=${layer}&styles=${layer}&x=${x}&y=${y}&height=${height}&width=${width}&srs=EPSG:4326&bbox=${bbox}&feature_count=10&info_format=application/json&ENV=&access_token=bNbAJOarotDDu0w2j1YKkJGju6bsza`).then((r) => r.json()))
  return constantData.infoLayers[id]
}

const getQuantile = ({ layerName, attribute, intervals }) => `${baseUrl}/rest/sldservice/${layerName}/classify.json?intervals=${intervals}&attribute=${attribute}`
const getFilterOptions = ({ layerName, attribute, max }) => ({
  url: `${baseUrl}/wps?service=WPS&outputFormat=json`,
  method: 'POST',
  mode: 'cors',
  credentials: 'omit',
  body: `<?xml version="1.0" encoding="UTF-8"?>
<wps:Execute xmlns:wps="http://www.opengis.net/wps/1.0.0" xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance" version="1.0.0" service="WPS" xsi:schemaLocation="http://www.opengis.net/wps/1.0.0 http://schemas.opengis.net/wps/1.0.0/wpsAll.xsd">
   <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">gs:PagedUnique</ows:Identifier>
   <wps:DataInputs>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">features</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">features</ows:Title>
         <wps:Data />
         <wps:Reference xmlns:xlink="http://www.w3.org/1999/xlink" mimeType="text/xml" xlink:href="http://geoserver/wfs" method="POST">
            <wps:Body>
               <wfs:GetFeature xmlns:wfs="http://www.opengis.net/wfs" service="WFS" version="1.0.0">
                  <wfs:Query typeName="${layerName}">
                     <ogc:SortBy xmlns:ogc="http://www.opengis.net/ogc">
                        <ogc:SortProperty>
                           <ogc:PropertyName>${attribute}</ogc:PropertyName>
                        </ogc:SortProperty>
                     </ogc:SortBy>
                  </wfs:Query>
               </wfs:GetFeature>
            </wps:Body>
         </wps:Reference>
      </wps:Input>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">fieldName</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">fieldName</ows:Title>
         <wps:Data>
            <wps:LiteralData>${attribute}</wps:LiteralData>
         </wps:Data>
      </wps:Input>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">maxFeatures</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">maxFeatures</ows:Title>
         <wps:Data>
            <wps:LiteralData>${max}</wps:LiteralData>
         </wps:Data>
      </wps:Input>
      <wps:Input>
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">startIndex</ows:Identifier>
         <ows:Title xmlns:ows="http://www.opengis.net/ows/1.1">startIndex</ows:Title>
         <wps:Data>
            <wps:LiteralData>0</wps:LiteralData>
         </wps:Data>
      </wps:Input>
   </wps:DataInputs>
   <wps:ResponseForm>
      <wps:RawDataOutput mimeType="application/json">
         <ows:Identifier xmlns:ows="http://www.opengis.net/ows/1.1">result</ows:Identifier>
      </wps:RawDataOutput>
   </wps:ResponseForm>
</wps:Execute>`
})

const getApiSector = () => `${baseUrl}layers_manager/get_info/`

const getLayersAndSector = async () => {
  const result = await fetch(getApiSector())
  const { layers, sector } = await result.json()
  return { layers, sector }
}
const getImgTooltip = (layerName) => `${baseUrl}geoserver/ows?service=WMS&request=GetLegendGraphic&format=image/png&WIDTH=20&HEIGHT=20&LAYER=${layerName}&legend_options=fontAntiAliasing:true;fontSize:12;forceLabels:on;bgColor:0xFFFFFF;`

export {
  getFeatureInfo,
  getQuantile,
  getFilterOptions,
  getLayersAndSector,
  getImgTooltip
}
