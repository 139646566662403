import {
  loadAppConfig,
  getBaseLayers,
  getCustomsIcons,
} from "utils/configQueries";
import { loadImages, mapOnPromise } from "utils/mapboxUtils";

import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";

import { overlap } from "utils/imageHelper";

let mapGL = null;

const initMap = createAsyncThunk(
  "map/initMap",
  async (mapInstance) => {
    mapGL = mapInstance;
    const mapOnLoad = mapOnPromise(mapInstance.map)("load");
    return mapOnLoad
      .then(async () => {
        await loadImages(mapInstance.map, getCustomsIcons());
        return true;
      })
      .catch(() => false);
  },
  {
    condition: () => mapGL === null,
  }
);

const loadLayers = createAsyncThunk("map/loadLayers", async () => {
  await loadAppConfig();

  const groups = {};
  /*
    // devuelve cada id y title de config.layersGroup
    getLayersGroups().forEach(({ id: idGroup, index = 0 }) => {
      groups[idGroup] = {}
      // devuelve el title, color y id de de cada layersGroup.layers
      getLayersByLayersGroupId(idGroup).forEach(({ id: idLayer, index: idxLayer }) => {
        groups[idGroup][idLayer] = {
          processingId: null,
          isVisible: false,
          index: idxLayer ?? index,
          order: 0
        }
      })
    })
    */
  const baseLayers = getBaseLayers();
  return {
    groups,
    baseLayers,
  };
});

const addImage = createAsyncThunk(
  "map/addImage",
  async ({ icons }) => {
    icons.forEach(async ({ id, iconData: { iconUrl, shadowUrl } }) => {
      // dispatch(mapActions.saveIcons({ id, iconUrl, shadowUrl }))
      const image =
        iconUrl.length > 0 && shadowUrl.length > 0
          ? await overlap(iconUrl, shadowUrl)
          : iconUrl;

      if (typeof image === "string") {
        loadImages(mapGL.map, [{ id, data: image }]);
      } else {
        mapGL.map.addImage(id, image);
      }
    });
  },
  {
    condition: () => mapGL !== null,
  }
);

const add = async (layer) => {
  const options = { ...layer.options };
  options.id = layer.id;
  const newLayer = mapGL.addLayerHelper(
    options,
    null,
    layer.displayPopup,
    layer.popupContent
  );
  return newLayer;
};

const reorderLayers = async (groups, layerId, index) => {
  // TODO: !arreglar el orden
  return;
  // eslint-disable-next-line no-unreachable
  const newOrder = Object.values(groups)
    .flatMap((group) => Object.entries(group))
    .filter(([id, { isVisible }]) => isVisible || id === layerId)
    .sort(
      ([ida, { index: ia, order: oa }], [idb, { index: ib, order: ob }]) => {
        const diff = ib - ia;
        if (diff === 0) {
          return (
            (idb === layerId ? 999999 : ob) - (ida === layerId ? 999999 : oa)
          );
        }
        return diff;
      }
    );
  // eslint-disable-next-line no-unreachable
  const count = newOrder.length;

  if (count > 0 && mapGL.map.getLayer("parcel_layer")) {
    mapGL.map.moveLayer(newOrder[0][0], "parcel_layer");
  }
  for (let idx = 1; idx < count; idx += 1) {
    mapGL.map.moveLayer(newOrder[idx][0], newOrder[idx - 1][0]);
  }
  if (
    mapGL.map.getLayer("explorer_layer") &&
    (count > 0 || mapGL.map.getLayer("parcel_layer"))
  ) {
    mapGL.map.moveLayer(
      "explorer_layer",
      count > 0 ? newOrder[count - 1][0] : "parcel_layer"
    );
  }
  const order =
    Object.values(groups)
      .flatMap((group) => Object.entries(group))
      .filter(
        ([id, { isVisible, index: idx }]) =>
          isVisible && idx === index && id !== layerId
      )
      .reduce(
        (maxOrder, [, { order: oa }]) => (oa > maxOrder ? oa : maxOrder),
        0
      ) + 1;
  // eslint-disable-next-line consistent-return
  return { order };
};

const toggle = async (layer, isVisible = null, index, groups) => {
  const { map } = mapGL;
  const layers = layer.options.layers ?? [layer];
  if (map.getLayer(layer.id)) {
    const visibility =
      map.getLayoutProperty(layer.id, "visibility") ?? "visible";
    const nextVisibility =
      isVisible !== null ? isVisible : visibility === "none";
    layers.forEach(({ id }) => {
      map.setLayoutProperty(
        id,
        "visibility",
        nextVisibility ? "visible" : "none"
      );
      reorderLayers(groups, id, index);
    });
    return 0;
  }
  return (
    add(layer, map)
      .then(() => mapOnPromise(mapGL.map)("idle"))
      .then(() => reorderLayers(groups, layer.id, index))
      // Se visualiza la capa luego de ser ordenada
      .then(() => {
        layers.forEach(({ id }) => {
          map.setLayoutProperty(id, "visibility", "visible");
        });
      })
      // eslint-disable-next-line no-console
      .catch((error) => console.warn("toggle add layer - catch error:", error))
  );
};

const getLayerState = (state, idLayer) => state.groups[idLayer];

// Notar que si el server falla el tilde parece dejar de funcionar
// si falla se desea el toggle se comporte como si el server funcionara bien
// Si se espera el tilde vuelve a funcionar, tarda porque se espera map este idle
const toggleLayer = createAsyncThunk(
  "map/toggleLayer",
  async ({ idGroup, isVisible = true }, { getState }) => {
    const state = getState();
    const fullLayers = getLayerState(state.map, idGroup);
    const toggling = Object.keys(fullLayers)
      .filter((l) => fullLayers[l].layerOptions)
      .map(async (l) => {
        const { index, layerOptions } = fullLayers[l];
        return toggle(layerOptions, isVisible, index, state.map.groups);
        // const { order } = await toggle(layerOptions, isVisible, index, state.map.groups)
        // console.log('order: ', order)
      });
    await Promise.all(toggling);
    /*
    const { isVisible, index } = getLayerState(state.map, idGroup, idLayer)
    const layer = getFullLayerConfig(idGroup, idLayer)
    const { order } = await toggle(layer, isVisible, index, state.map.groups)
    const onPromise = mapOnPromise(mapGL.map)
    onPromise('idle')
      // eslint-disable-next-line no-console
      .catch((error) => console.warn('toggleLayer catch error:', error))
    return { order }
    */
  },
  {
    condition: ({ idGroup }, { getState }) => {
      const state = getState();
      const layerState = getLayerState(state.map, idGroup);
      return state.map.isMapReady && layerState.processingId === null;
    },
  }
);

const map = createSlice({
  name: "map",
  initialState: {
    isMapReady: false,
    defaultMapStyle: null,
    camera: {
      lat: -33.03744,
      lng: -68.8853,
      zoom: 14.84,
      pitch: 0,
      bearing: 0,
    },
    bbox: null,
    selectedCoords: null,
    clickedPoint: null,
    selectedCoordsWithRightClick: null,
    groups: {},
    explorerLayers: {},
    icons: {},
    tutorial: null,
  },
  reducers: {
    cameraUpdated: (
      draftState,
      {
        payload: {
          lat: newLat,
          lng: newLng,
          zoom: newZoom,
          pitch: newPitch,
          bearing: newBearing,
        },
      }
    ) => {
      const { lat, lng, zoom, bearing } = draftState.camera;
      draftState.camera = {
        lat: newLat || lat,
        lng: newLng || lng,
        zoom: newZoom || zoom,
        pitch: newPitch,
        bearing: newBearing || bearing,
      };
    },
    setMapReady: (draftState) => {
      draftState.isMapReady = true;
    },
    clickOnMap: (draftState, { payload: { lng, lat, x, y } }) => {
      draftState.selectedCoords = { lng, lat };
      draftState.clickedPoint = { x, y };
    },
    removeCoords: (draftState) => {
      draftState.clickedPoint = null;
      draftState.selectedCoords = null;
    },
    removeSelectedCoords: (draftState) => {
      draftState.selectedCoords = null;
    },
    removeclickedPoint: (draftState) => {
      draftState.clickedPoint = null;
    },
    rightClickOnMap: (draftState, action) => {
      draftState.selectedCoordsWithRightClick = action.payload;
    },
    addGroup: (draftState, { payload }) => {
      payload.forEach((Group) => {
        draftState.groups[Group.id] = {
          processingId: null,
          isVisible: false,
        };
        Group.layers.forEach((layer) => {
          draftState.groups[Group.id][layer.id] = {
            index: layer.index,
            order: layer.order,
            layerOptions: layer.layerOptions,
          };
        });
      });
    },
    openTutorial: (draftState, action) => {
      draftState.tutorial = action.payload;
    },
    setBbox: (draftState, { payload }) => {
      draftState.bbox = payload;
    },
  },
  extraReducers: {
    [initMap.fulfilled]: (draftState, action) => {
      draftState.isMapReady = action.payload;
    },
    [toggleLayer.pending]: (
      draftState,
      {
        meta: {
          requestId,
          arg: { idGroup },
        },
      }
    ) => {
      const layerState = getLayerState(draftState, idGroup);
      layerState.processingId = requestId;
      layerState.isVisible = !layerState.isVisible;
    },
    [toggleLayer.fulfilled]: (
      draftState,
      {
        meta: {
          requestId,
          arg: { idGroup },
        },
      }
    ) => {
      const layerState = getLayerState(draftState, idGroup);
      if (layerState.processingId === requestId) {
        layerState.processingId = null;
      }
    },
    [toggleLayer.rejected]: (
      draftState,
      {
        meta: {
          requestId,
          arg: { idGroup },
        },
      }
    ) => {
      const layerState = getLayerState(draftState, idGroup);
      if (layerState?.processingId === requestId) {
        layerState.processingId = null;
      }
    },
    [loadLayers.fulfilled]: (
      draftState,
      {
        payload: {
          groups,
          baseLayers: { sources, layers, light },
        },
      }
    ) => {
      draftState.groups = groups;
      draftState.defaultMapStyle = {
        version: 8,
        sources,
        layers,
        light,
        glyphs: "https://fonts.openmaptiles.org/{fontstack}/{range}.pbf",
      };
    },
  },
});

export default map.reducer;

const actions = {
  ...map.actions,
  initMap,
  toggleLayer,
  loadLayers,
  addImage,
};
export { actions };
