import { configureStore } from '@reduxjs/toolkit'

import rootReducer from './ducks'

const store = configureStore({
  reducer: rootReducer, // persistedReducer,
  middleware: (getDefaultMiddleware) => {
    const middleware = getDefaultMiddleware()
    return middleware
  }
})

// persistStore(store)

export default store
