import React from 'react'
import { BrowserRouter } from 'react-router-dom'
import PropTypes from 'prop-types'
import {
  Box
} from '@material-ui/core'
import Routes from './routes'

import './theme/mapLibre.css'

export const ModalContext = React.createContext({ isModalOpen: true })

export default function App({ isAuthenticated }) {
  return (
    <Box>
      <BrowserRouter>
        <Routes authed={isAuthenticated} />
      </BrowserRouter>
    </Box>
  )
}

App.propTypes = {
  isAuthenticated: PropTypes.bool
}
App.defaultProps = {
  isAuthenticated: false
}
