import { useState, useEffect } from "react";
import { getQuantile } from "utils/apiConfig";

const useFilterRange = (filters) => {
  const [rangeOptions, setRangeOptions] = useState([]);
  useEffect(() => {
    let isSubscribed = true;
    const attribute=  filters[0];
    const layerName= filters[1];
    const intervals= filters[2];
    if (attribute) {
      const url = getQuantile({ layerName, attribute, intervals });
      fetch(url)
        .then((data) => data.json())
        .then(({ Rules: { Rule: range } }) => {
          if (!Array.isArray(range)) {
            return;
          }
          const ranges = range.map(
            (
              {
                Title,
                Filter: {
                  And: {
                    PropertyIsGreaterThanOrEqualTo: {
                      Literal: minInclusive,
                    } = {},
                    PropertyIsLessThanOrEqualTo: { Literal: maxInclusive } = {},
                    PropertyIsGreaterThan: { Literal: min } = {},
                    PropertyIsLessThan: { Literal: max } = {},
                  } = 0,
                },
                PointSymbolizer: {
                  Graphic: {
                    Mark: {
                      Fill: {
                        CssParameter: { $: colorPoint },
                      },
                    },
                  },
                } = {
                  Graphic: {
                    Mark: {
                      Fill: {
                        CssParameter: {},
                      },
                    },
                  },
                },
                PolygonSymbolizer: {
                  Fill: {
                    CssParameter: { $: colorPolygon },
                  },
                } = {
                  Fill: {
                    CssParameter: {},
                  },
                },
                LineSymbolizer: {
                  Stroke: {
                    CssParameter: { $: color },
                  },
                } = {
                  Stroke: {
                    CssParameter: {},
                  },
                },
              },
              idx
            ) => ({
              id: idx,
              title: Title,
              minInclusive,
              maxInclusive,
              min,
              max,
              color: colorPolygon || colorPoint || color,
            })
          );
          if (isSubscribed) {
            setRangeOptions(ranges);
          }
        });
    }

    return () => {
      isSubscribed = false;
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return { rangeOptions };
};

export default useFilterRange;
