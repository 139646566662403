const routesSliceConfig = {
  initialState: {
    routes: {
      byId: {},
      favorites: [],
      favoritesMax: 10
    }
  },
  reducers: {
    /**
     * Limpia las rutas que expiraron en el tiempo
     */
    cleanExpiredRoutes: () => {
      // TODO: limpiar rutas de busquedas expiradas
    },
    /**
     * Agrega la rutas
     */
    routesObtained: (draftState, { payload: routes }) => {
      routes.forEach(({
        id, tiempo, descripcionResumen, legs, color, firstStop
      }) => {
        draftState.routes.byId[id] = {
          id,
          tiempo,
          descripcionResumen,
          legs,
          color,
          firstStop
        }
      })
    },
    routesError: (draftState) => {
      draftState.routes.byId = {}
    },
    addFavoriteRoute: (draftState, { payload: idFav }) => {
      draftState.routes.favorites.push({ id: idFav })
    },
    removeFavoriteRoute: (draftState, { payload: idFav }) => {
      draftState.routes.favorites = draftState.routes.favorites.filter(({ id }) => id !== idFav)
    }
  }
}

export default routesSliceConfig
