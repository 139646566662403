import React from "react";

import { Paper } from "@material-ui/core";

import Map from "components/Map";
import Marker from "components/Marker";
import FeaturesInfo from "components/FeaturesInfo";
import Sections from "components/Sections";
import SideBar from "components/SideBar";
import Layer from "components/Layer";

import useLayers from "utils/useLayers";
import { useSelector } from "react-redux";
import "maplibre-gl/dist/maplibre-gl.css";
import useStyles from "./styles";

const Home = () => {
  const classes = useStyles();

  // Se cargan las listas de capas
  const { layers } = useLayers();
  // Coordenadas obtenidas por el buscador
  const coordinateLng = useSelector(
    (state) => state.location.place.location?.coordinates[0]
  );
  const coordinateLat = useSelector(
    (state) => state.location.place.location?.coordinates[1]
  );
  const layerDefault = useSelector((state) => state.layers.layerDefault);
  const layersIds = useSelector((state) => state.layers?.ids);
  const isMapReady = useSelector((state) => state.map.isMapReady);

  return (
    <Paper className={classes.root}>
      <Sections />
      <SideBar />
      <Map>
        {/* <FeaturesInfo /> */}
        {/* Marcador al hacer una busqueda */}
        {coordinateLat && coordinateLng && (
          <Marker coords={{ lat: coordinateLat, lng: coordinateLng }} />
        )}
        {layers.length > 0 &&
          layers.map(({ sourceId, source, cql, style, opacity, id }) => (
            <Layer
              key={style.id}
              sourceId={sourceId}
              sourceConfig={source}
              cql={cql}
              layerConfig={style}
              opacity={opacity}
              layers={layers}
              listIdLayersState={layersIds}
              id={id}
              isMapReady={isMapReady}
            />
          ))}
        {layerDefault.map(({ sourceId, source, cql, style, opacity, id }) => (
          <Layer
            key={style.id}
            sourceId={sourceId}
            sourceConfig={source}
            cql={cql}
            layerConfig={style}
            opacity={opacity}
            layers={layers}
            listIdLayersState={layersIds}
            id={id}
            isMapReady={isMapReady}
          />
        ))}
      </Map>
    </Paper>
  );
};

export default Home;
