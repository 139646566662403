import React from 'react'
import { Button, Box, Typography } from '@material-ui/core'
import { useDispatch, useSelector } from 'react-redux'
import { actions as mapActions } from 'state/ducks/map'
import useStyles from './styles'
import img3D from '../../img/3D.png'
import img2D from '../../img/2D.png'

const Button3d = () => {
  const cameraPitch = useSelector((state) => state.map.camera.pitch)
  const dispatch = useDispatch()
  const classes = useStyles()

  const button2dAnd3d = (inclination) => {
    dispatch(
      mapActions.cameraUpdated({
        pitch: inclination === 0 ? 60 : 0
      })
    )
  }

  return (
    <Button onClick={() => button2dAnd3d(cameraPitch)}>
      {
        cameraPitch ? (
          <Box className={classes.conteiner}>
            <img
              src={img2D}
              alt="img2D"
              className={classes.img}
            />
            <Typography className={classes.text}>
              2D
            </Typography>
          </Box>
        ) : (
          <Box className={classes.conteiner}>
            <img
              src={img3D}
              alt="img3D"
              className={classes.img}
            />
            <Typography className={classes.text}>
              3D
            </Typography>
          </Box>
        )
    }
    </Button>
  )
}
export default Button3d
