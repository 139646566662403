import { withStyles } from '@material-ui/core'

import RadioButtonCheckedIcon from '@material-ui/icons/RadioButtonChecked'

const CustomOption = withStyles((theme) => ({
  root: {
    borderRadius: 10, color: '#4D4D4D', backgroundColor: '#0B72BB'
  }
}))(RadioButtonCheckedIcon)

export default CustomOption
