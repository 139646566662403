const searchesSliceConfig = {
  initialState: {
    searches: {
      byId: {},
      maxTime: 15 * 60 * 1000
    }
  },
  reducers: {
    /**
     * Agrega la busqueda con sus rutas asociadas.
     */
    searchObtained: (draftState, { payload: { id, routes } }) => {
      draftState.searches.byId[id] = {
        timeStamp: Date.now(),
        routes
      }
    }
  }
}

export default searchesSliceConfig
