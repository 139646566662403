import { makeStyles } from '@material-ui/core/styles'
import { CenterFocusStrong } from '@material-ui/icons'

export default makeStyles((theme) => ({
  card: {
    marginBottom: theme.spacing(2.5),
    backgroundColor: '#F1F1F3',
    borderRadius: '5px',
    borderLeftStyle: 'solid',
    borderWidth: 'thick',
    lineHeight: theme.spacing(20),
    width: theme.spacing(38.13),
    '&:hover': { cursor: 'pointer' }
  },
  formControl: {
    marginRight: 0
  },
  listItem: {
    padding: 0
  },
  color: {
    width: theme.spacing(1.5),
    height: theme.spacing(1.5),
    marginRight: theme.spacing(0.8),
    border: '1px solid grey'
  },
  divider: {
    height: theme.spacing(0.2)
  },
  boxIcons: {
    width: '100%'
  },
  reference: {
    padding: 0,
    float: 'right',
    height: '18px'
  },
  info: {
    padding: 0,
    float: 'right',
    height: '18px'
  },
  iconButton: {
    padding: 0,
    float: 'right',
    marginLeft: theme.spacing(0.5)
  },
  downloadIcon: {
    height: '18px'
  },
  referenceItems: {
    padding: 5
  },
  referenceTitle: {
    paddingTop: theme.spacing(0.1)
  },
  avatar: {
    width: theme.spacing(3),
    height: theme.spacing(3),
    marginRight: theme.spacing(1)
  },
  itemMap: {
    '&:hover': { cursor: 'pointer', backgroundColor: 'white' }
  },
  itenInfo: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(2),
    backgroundColor: 'white'
  },
  container: {
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    height: 94,
    backgroundColor: '#f3bf00',
    paddingTop: 25,
    paddingLeft: 25,
    minWidth: 280
  },
  sectionTitle: {
    textAlign: 'left',
    color: '#595958',
    fontSize: 20,
    fontWeight: 900,
    paddingLeft: 10,
    marginTop: -6
  },
  subCategory: {
    display: 'flex',
    flexDirection: 'column',
    fontSize: 14,
    paddingBottom: 12
  },
  formGroup: {
    paddingTop: 13,
    paddingLeft: 20,
    paddingRight: 20,
    fontWeight: 900
  },
  subtile: {
    padding: 5
  },
  capa: {
    fontSize: 13,
    padding: 5
  },
  download: {
    width: '50px',
    marginTop: '-42px',
    marginLeft: '220px'
  },
  buttonLink: {
    width: '50px',
    marginTop: '-42px',
    marginLeft: '180px',
    marginBottom: '-3px'
  },
  buttonTooltip: {
    width: '50px',
    marginTop: '-42px',
    marginLeft: '140px',
    marginBottom: '-3px'
  },
  button: {
    color: 'grey'
  }
}))
