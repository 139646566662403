export const data = (info) => {
  const datos = info.map(({
    nomenclatu,
    callepadro,
    alturapadr,
    localidadp,
    zonificaci,
    suptotal,
    frenmetros,
    tipo_lote,
    id_bp,
    id,
    link_usos,
    sup_regl_m,
    sup_real_m,
    sup_real_1,
    frente_min,
    fos_max,
    fot_max,
    naturalidad,
    ret_front1,
    ret_front2,
    ochava,
    ret_lat1,
    ret_lat2,
    ret_post,
    h_ini,
    h_max,
    vereda_min,
    vol_emerg,
    unif_ret,
    ph_permitido,
    alerta_sup,
    pedemonte,
    pendiente,
    cprincipal,
    ruta,
    afectacion_calle,
    retiro_seg,
    aluvional
  }) => {
    const data = [{
      description: nomenclatu,
      title: 'Nomenclatura'
    },
    {
      description: callepadro,
      title: 'Calle'
    },
    {
      description: alturapadr,
      title: 'Altura'
    },
    {
      description: localidadp,
      title: 'Localidad'
    },
    {
      description: zonificaci,
      title: 'Zonificación'
    },
    {
      description: suptotal,
      title: 'Superficie total'
    },
    {
      description: frenmetros,
      title: 'Frente (en metros):'
    },
    {
      description: tipo_lote,
      title: 'Tipo de lote'
    },
    {
      description: id_bp,
      title: 'Bien Patrimonial'
    }
    ].filter((e) => e.description)

    const usos = [{ title: link_usos }]

    const edf = [{
      description: sup_regl_m,
      title: 'Superficie reglamentaria mínima (m2)'
    },
    {
      description: sup_real_m,
      title: 'Superficie real mínima (m2)'
    },
    {
      description: sup_real_1,
      title: 'Superficie real máxima (m2):'
    },
    {
      description: frente_min,
      title: 'Frente mínimo (m):'
    },
    {
      description: fos_max,
      title: 'Factor de Ocupación del Suelo (FOS) máximo:'
    },
    {
      description: fot_max,
      title: 'Factor de Ocupación Total (FOT) máximo '
    },
    {
      description: naturalidad,
      title: 'Parámetro de ocupación de suelo que deberá conservar las características naturales del ambiente pedemontano'
    },
    {
      description: ret_front1,
      title: 'Retiro frontal 1'
    },
    {
      description: ret_front2,
      title: 'Retiro frontal 2'
    },
    {
      description: ochava,
      title: 'Medida de la Ochava (m)'
    },
    {
      description: ret_lat1,
      title: 'Retiro lateral'
    },
    {
      description: ret_lat2,
      title: 'Retiro lateral'
    },
    {
      description: ret_post,
      title: 'Retiro posterior'
    },
    {
      description: h_ini,
      title: 'Altura de aplicación de retiros'
    },
    {
      description: h_max,
      title: 'Altura máxima'
    },
    {
      description: vereda_min,
      title: 'Veredas'
    }
    ].filter((e) => e.description)

    const alert = [{
      description: vol_emerg,
      title: 'Alerta de Volumen Emergente'
    },
    {
      description: unif_ret,
      title: 'Alerta de Unificación de Retiros'
    },
    {
      description: ph_permitido,
      title: 'Alerta de Propiedad Horizontal'
    },
    {
      description: vereda_min,
      title: 'Alerta de Vereda Mínima'
    },
    {
      description: alerta_sup,
      title: 'Alerta de Superficie'
    },
    {
      description: pedemonte,
      title: 'Alerta de Loteo de Pedemonte'
    }]

    const aft = [
      {
        description: pendiente,
        title: 'Afectación de Pendiente'
      },
      {
        description: cprincipal,
        title: 'Afectación de Calle Principal'
      },
      {
        description: ruta,
        title: 'Afectación de Rutas'
      },
      {
        description: afectacion_calle,
        title: 'Afectación de Fachadas'
      },
      {
        description: retiro_seg,
        title: 'Afectación de Retiros de Seguridad por Río Mendoza'
      },
      {
        description: aluvional,
        title: 'Afectación por Retiro Aluvional'
      }
    ]
    const parcelId = id
    return ({
      datos: data, usosPermitidos: usos, edificabilidad: { edf, alert, aft }, parcelId
    })
  })[0]
  return (datos)
}
