import React from 'react'

import { Box, Paper } from '@material-ui/core'
import Scrollbar from 'react-smooth-scrollbar'

import { getCategoryTitle, getSectionInfo } from 'utils/configQueries'

import { useSelector } from 'react-redux'

import HeaderHeaderSection from 'components/Sections/HeaderHeaderSection'

import PropTypes from 'prop-types'
import { useMediaQuery } from 'react-responsive'
import useStyles from './ContainerBarStyles'

const ContainerBar = ({ children, type }) => {
  const classes = useStyles()

  const sectionId = useSelector((state) => state.categories.sectionId)
  const categoryTitle = sectionId[0] === 'Capas' ? getCategoryTitle(sectionId[0]) : ''
  const isTabletOrMobile = useMediaQuery({ query: '(max-width: 960px)' })
  const lastIndex = sectionId.length - 1[0]
  const { title, info } = lastIndex > 0
    ? getSectionInfo(sectionId[lastIndex]) : { title: null, info: null }

  const height = sectionId.length > 1
    ? '80vh' : '100vh'

  return (
    <Box className={isTabletOrMobile ? classes.responsive : classes.container}>
      <Paper elevation={0}>
        <HeaderHeaderSection
          categoryTitle={categoryTitle}
          sectionTitle={title}
          info={info}
        />
        {/**  <HeaderSection
          categoryTitle={categoryTitle}
          sectionTitle={title}
          info={info}
        /> */}
      </Paper>

      <Scrollbar>
        <Box className={classes.padding} style={{ height }}>
          {children}
        </Box>
      </Scrollbar>
    </Box>
  )
}
ContainerBar.propTypes = {
  children: PropTypes.oneOfType([PropTypes.object, PropTypes.array]).isRequired,
  type: PropTypes.string.isRequired
}

export default ContainerBar
