import { createSlice } from '@reduxjs/toolkit'

const measure = createSlice({
  name: 'measure',
  initialState: {
    showLine: false
  },
  reducers: {
    lineButton: (draftState, { payload }) => {
      draftState.showLine = payload
    }
  }
})

export default measure.reducer

const actions = { ...measure.actions }
export { actions }
