import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  conteiner: {
    display: 'flex',
    flexDirection: 'column',
    width: '10%'
  },
  conteinerDatos: {
    display: 'flex',
    flexDirection: 'column',
    padding: 10
  },
  sectionTitle: {
    backgroundColor: theme.palette.secondary.main,
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    paddingTop: 25,
    paddingLeft: 25,
    width: '100%',
    height: 94
  },
  paper: {
    position: 'absolute',
    position: 'absolute',
    left: '40%',
    top: '10%',
    width: 500,
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5]
  },
  conteinerDatos: {
    display: 'flex',
    alignItems: 'center',
    minHeight: 40
  },
  datosAndDescription: {
    width: '80%',
    paddingLeft: 20,
    paddingRight: 20
  },
  description: {
    width: '100%'
  },
  title: {
    textAlign: 'center',
    fontSize: 20,
    fontWeight: 900,
    paddingLeft: 8,
    marginTop: -3
  },
  info: {
    borderRadius: 5,
    backgroundColor: '#D9D9D9',
    width: '100%',
    display: 'flex',
    justifyContent: 'space-between',
    padding: 12,
    margin: 10
  },
  conteinerDownload: {
    display: 'flex',
    alignItems: 'center'
  },
  buttonDownload: {
    display: 'flex',
    padding: 0,
    minWidth: 30
  },
  icon: {
    fontSize: 40
  },
  iconParcel: {
    display: 'flex',
    justifyContent: 'center',
    height: '90%',
    alignItems: 'center'
  }
}))
