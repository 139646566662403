import { createSlice } from '@reduxjs/toolkit'
import places from './places'
import routes from './routes'
import searches from './searches'

const entities = createSlice({
  name: 'entities',
  initialState: {
    ...places.initialState,
    ...routes.initialState,
    ...searches.initialState
  },
  reducers: {
    ...places.reducers,
    ...routes.reducers,
    ...searches.reducers
  }
})

export default entities.reducer

const actions = { ...entities.actions }
export { actions }
