import { makeStyles } from '@material-ui/core/styles'

export default makeStyles((theme) => ({
  container: {
    backgroundColor: theme.palette.secondary.main,
    color: 'white'
  },
  subTitle: {
    marginBottom: theme.spacing(2),
    paddingBottom: theme.spacing(1)
  },
  button: {
    padding: 0,
    paddingRight: theme.spacing(0.5),
    marginBottom: '5px',
    minWidth: '0px !important'
  },
  tooltip: {
    marginLeft: theme.spacing(0.5),
    marginTop: '5px'
  },

}))
