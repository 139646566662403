import { IControl } from "maplibre-gl";

export interface MapLibreButtonRotateControlOptions {
  name: string;
  startRotation: (value: boolean) => void;
  cameraPitch: number;
  map: maplibregl.Map;
}

export default class ButtonRotateControl implements IControl {
  _options: MapLibreButtonRotateControlOptions;
  _container: HTMLElement;
  _button: HTMLButtonElement;

  constructor(options: MapLibreButtonRotateControlOptions) {
    this._options = options;
    this._container = document.createElement("div");
    this._container.classList.add("maplibregl-ctrl");
    const button2dAnd3d = document.createElement("div");

    this._button = document.createElement("button");
    this._button.className = "maplibregl-ctrl-rotate";
    this._button.addEventListener("click", () => {
      const { startRotation } = this._options;
      startRotation(false);
    });
    let img = document.createElement("img");
    img.src = "./iconPlay.svg";
    img.style.margin = "auto";
    this._button.appendChild(img);
    this._button.title = options.name;
    this._button.style.cursor = "pointer";

    button2dAnd3d.appendChild(this._button);
    this._container.appendChild(button2dAnd3d);
  }

  onAdd(map: maplibregl.Map): HTMLElement {
    return this._container;
  }
  setTitle(title: string) {
    this._button.title = title;
  }
  onRemove(): void {
    this._container.parentNode?.removeChild(this._container);
  }
}
