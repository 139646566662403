/* eslint-disable default-case */
import React, { useEffect, useState } from 'react'

import {
  Modal, Box, Typography, IconButton, Button
} from '@material-ui/core'

import ClearIcon from '@material-ui/icons/Clear'
import { actions as mapActions } from 'state/ducks/map'
import { useDispatch, useSelector } from 'react-redux'
import icons from 'utils/svgIcons'
import useStyles from './styles'

const Tutorial = () => {
  const tutorialOpenandClose = useSelector((state) => state.map.tutorial)
  const [activeStep, setActiveStep] = useState(0)
  const classes = useStyles()
  const dispatch = useDispatch()

  const handleNext = () => {
    if (activeStep <= 1) {
      setActiveStep((prevActiveStep) => prevActiveStep + 1)
    }
  }

  const handleBack = () => {
    if (activeStep >= 1) {
      setActiveStep((prevActiveStep) => prevActiveStep - 1)
    }
  }

  const handleClose = () => {
    dispatch(mapActions.openTutorial(!tutorialOpenandClose))
  }

  useEffect(() => {
    if (!tutorialOpenandClose) {
      setActiveStep(0)
    }
  }, [tutorialOpenandClose])

  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return (
          <Box className={classes.sectionBienvenida}>
            <Box className={classes.containerTextandImg}>
              <Typography className={classes.titleBienvenida}>
                Bienvenidos
              </Typography>
              <Typography className={classes.textBienvenida}>
                Comenzar tutorial
              </Typography>
            </Box>
          </Box>
        )
      case 1:
        return (
          <Box>
            <Box className={classes.sectionModal}>
              <Box className={classes.containerText}>
                <Typography className={classes.titleStep}>
                  Sectores del visor
                </Typography>
                <Typography className={classes.mainText}>
                  El visor tiene principalmente 3 sectores:
                </Typography>
                <Typography className={classes.mainTextTools}>
                  1) La barra de navegación, 2) el mapa y la 3) sección de herramientas del mapa.
                </Typography>
              </Box>
              <Box className={classes.containerImg}>
                <img
                  className={classes.imgpage}
                  alt="Frame1"
                  src="assets/imgTutorial/tutorial1.png"
                />
              </Box>
            </Box>
            <Typography className={classes.titleStep1}>
              Barra de navegación
            </Typography>
            <Typography className={classes.mainText1}>
              En esta sección podemos acceder a la información de las parcelas seleccionadas,
              y operar con las distintas capas de información contextual, así como también,
              generar reportes con la información básica de las parcelas y explorar el código
              urbanístico.
            </Typography>
          </Box>
        )
      case 2:
        return (
          <Box className={classes.sectionModal}>
            <Box className={classes.containerText}>
              <Typography className={classes.titleStep}>
                Mapa (1)
              </Typography>
              <Typography className={classes.mainText}>
                En él veremos la información desplegada sobre las capas base (tipos de mapa)
              </Typography>
              <Typography className={classes.titleStep}>
                Herramientas (2)
              </Typography>
              <Typography className={classes.mainText}>
                Con ellas podemos operar sobre el mapa: Hacer zoom, ver en pantalla completa,
                medir distancias, entre otras opciones. Además, seleccionar las capas base y
                la inclinación de la visualización.
              </Typography>
              <Typography className={classes.titleStep}>
                Buscador (3)
              </Typography>
              <Typography className={classes.mainText}>
                Tipear una dirección para que nos lleve a ella con un marcador.
              </Typography>
            </Box>
            <Box className={classes.containerImg}>
              <img
                className={classes.imgpage}
                alt="Frame1"
                src="assets/imgTutorial/tutorial2.png"
              />
            </Box>
          </Box>
        )
    }
  }

  return (
    <Modal
      open={tutorialOpenandClose}
      onClose={handleClose}
      className={classes.tutorialModal}
    >
      <Box className={classes.container}>
        <Box className={classes.header}>
          <Typography className={classes.title}>
            <Box className={classes.iconoTutorial}>
              {icons.find((i) => i.id === 'iconoTutorial')?.path}
            </Box>
            Ayuda
          </Typography>
          <IconButton onClick={handleClose}>
            <ClearIcon className={classes.iconClose} />
          </IconButton>
        </Box>
        <Box className={classes.containerTutorial}>
          {getStepContent(activeStep)}
        </Box>
        <Box
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'flex-end',
            height: '27%'
          }}
        >
          {activeStep === 0 ? (
            <>
              <Button
                size="small"
                onClick={handleNext}
                style={{ marginRight: 20, marginBottom: 20 }}
              >
                {icons.find((i) => i.id === 'ButonRightStart')?.path}
              </Button>
            </>
          ) : (
            <>
              <Button
                size="small"
                onClick={handleBack}
                style={{ marginBottom: 20, padding: 0, transition: 'none' }}
              >
                {icons.find((i) => i.id === 'ButonLeft')?.path}
              </Button>
              { activeStep !== 2 ? (
                <Button
                  size="small"
                  onClick={handleNext}
                  style={{ marginBottom: 20, marginRight: 15, padding: 0 }}
                >
                  {icons.find((i) => i.id === 'ButonRight')?.path}
                </Button>
              ) : (
                <IconButton
                  size="small"
                  onClick={handleClose}
                  style={{
                    marginBottom: 20, marginRight: 15, padding: 0, border: '3px solid #575756', width: 40, height: 40, opacity: 0.5
                  }}
                >
                  <ClearIcon className={classes.iconClose} />
                </IconButton>
              )}
            </>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default Tutorial
